import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyAg21nz9kK-vocOKV9dxzuOwNc0AjrydVY",
    authDomain: "solid-ground-ent.firebaseapp.com",
    projectId: "solid-ground-ent",
    storageBucket: "solid-ground-ent.appspot.com",
    messagingSenderId: "476164701759",
    appId: "1:476164701759:web:3bc694668984f6ddd6828a",
    measurementId: "G-SWKT9E0WBS"
};

firebase.initializeApp(config);

export default firebase.database();